export const SIDEBAR_OPEN = "SIDEBAR_OPEN";
export const SIDEBAR_CLOSE = "SIDEBAR_CLOSE";
export const GET_PRODUCTS_BEGIN = "GET_PRODUCTS_BEGIN";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";
export const GET_SINGLE_PRODUCT_BEGIN = "GET_SINGLE_PRODUCT_BEGIN";
export const GET_SINGLE_PRODUCT_SUCCESS = "GET_SINGLE_PRODUCT_SUCCESS";
export const GET_SINGLE_PRODUCT_ERROR = "GET_SINGLE_PRODUCT_ERROR";
export const GET_SINGLE_PRODUCT_BEGIN1 = "GET_SINGLE_PRODUCT_BEGIN1";
export const GET_SINGLE_PRODUCT_SUCCESS1 = "GET_SINGLE_PRODUCT_SUCCESS1";
export const GET_SINGLE_PRODUCT_ERROR1 = "GET_SINGLE_PRODUCT_ERROR1";
export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const SET_GRIDVIEW = "SET_GRIDVIEW";
export const SET_LISTVIEW = "SET_LISTVIEW";
export const UPDATE_SORT = "UPDATE_SORT";
export const SORT_PRODUCTS = "SORT_PRODUCTS";
export const UPDATE_FILTERS = "UPfDATE_FILTERS";
export const FILTER_PRODUCTS = "FILTER_PRODUCTS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const TOGGLE_CART_ITEM_AMOUNT = "TOGGLE_CART_ITEM_AMOUNT";
export const CLEAR_CART = "CLEAR_CART";
export const COUNT_CART_TOTALS = "COUNT_CART_TOTALS";
export const GET_HOME_CATEGORY_BEGIN = "GET_HOME_CATEGORY_BEGIN";
export const GET_HOME_CATEGORY_SUCCESS = "GET_HOME_CATEGORY_SUCCESS";
export const GET_HOME_CATEGORY_ERROR = "GET_HOME_CATEGORY_ERROR";
export const GET_HOME_DATA_BEGIN = "GET_HOME_DATA_BEGIN";
export const GET_HOME_DATA_SUCCESS = "GET_HOME_DATA_SUCCESS";
export const GET_HOME_DATA_ERROR = "GET_HOME_DATA_ERROR";

export const GET_CATEGORY_LIST_BEGIN = "GET_CATEGORY_LIST_BEGIN";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_ERROR = "GET_CATEGORY_LIST_ERROR";

// login
export const GET_LOGIN_BEGIN = "GET_LOGIN_BEGIN";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_ERROR = "GET_LOGIN_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";

export const MALL_SIGNUP_BEGIN = "MALL_SIGNUP_BEGIN";
export const MALL_SIGNUP_SUCCESS = "MALL_SIGNUP_SUCCESS";
export const MALL_SIGNUP_FAIL = "MALL_SIGNUP_FAIL";
// wishlist
export const WISHLIST_PRODUCT = "WISHLIST_PRODUCT";
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_WISHLIST = "REMOVE_WISHLIST";
export const GET_WISHLIST = "GET_WISHLIST";
export const LOAD_PRODUCTS_WISHLIST = "LOAD_PRODUCTS_WISHLIST";
export const GET_WISHLIST_BEGIN = "GET_WISHLIST_BEGIN";
export const GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS";
export const GET_WISHLIST_ERROR = "GET_WISHLIST_ERROR";

// Wishlist Update
export const ADD_TO_WISHLIST_BEGIN = "ADD_TO_WISHLIST_BEGIN";
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_ERROR = "ADD_TO_WISHLIST_ERROR";

// cehkout
export const PLACE_ORDER_BEGIN = "PLACE_ORDER_BEGIN";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_ERROR = "PLACE_ORDER_ERROR";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_SINGLE_ORDER_DETILS = "GET_SINGLE_ORDER_DETILS";
export const RETURN_ORDER = "RETURN_ORDER";
export const RE_ORDER_PRODUCT = "RE_ORDER_PRODUCT";
export const DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE";
export const STORE_TICKERS_LIST = "STORE_TICKERS_LIST";

// address
export const ADDRESS_BEGIN = "ADDRESS_BEGIN";
export const ADDRESS_ADD_SUCCESS = "ADDRESS_ADD_SUCCESS";
export const ADDRESS_GET_SUCCESS = "ADDRESS_GET_SUCCESS";
export const ADDRESS_ERROR = "ADDRESS_ERROR";
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_STATES = "GET_STATES";
