import logo from "../assets/logo.png";
import ImageComingSoon from "../assets/imagecommingsoon.jpeg";
import car from "../assets/car.png";
import order from "../assets/order.png";
import payment from "../assets/payment.png";
import returnOrder from "../assets/returnOrder.png";
import school_logo_demo from "../assets/school_logo_demo.png";
import Return from "../assets/Return.png";
import aboutbg from "../assets/aboutbg.jpeg";
import shippingIcon from "../assets/shippingIcon.png";
import return_order from "../assets/return_order.png";
import secure_pay from "../assets/secure_pay.png";
import AboutUs from "../assets/AboutUs.jpeg";
import aboutDeepak from "../assets/aboutDeepak.jpeg";
import aboutDeepak1 from "../assets/aboutDeepak1.jpeg";
import aboutDeepak2 from "../assets/aboutDeepak2.jpeg";

export default {
  logo,
  AboutUs,
  aboutDeepak,
  aboutDeepak1,
  aboutDeepak2,
  car,
  order,
  payment,
  returnOrder,
  ImageComingSoon,
  school_logo_demo,
  Return,
  aboutbg,
  shippingIcon,
  secure_pay,
  return_order,
};
